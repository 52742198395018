@import url('https://fonts.googleapis.com/css?family=Oswald:500');
.Nav{
  height: 85px;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  z-index: 12;
}

.NavMenu{
  width: 100%;
  
  top:50px;
  text-align:center;
}
.NavLink{
  font-family: 'Oswald', sans-serif;
  font-weight:500;
  text-transform:uppercase;
  text-decoration:none;
  color:#16151b;
  margin:0 15px;
  font-size:16px;
  letter-spacing:1px;
  position:relative;
  cursor: pointer;
  border: none;
  display:inline-block;
  transition: box-shadow 0.3s; /* Smooth transition for focus */
  outline: none;
}
.NavLink:before{
  content:'';
  position: absolute;
  width: 100%;
  height: 3px;
  background:#16151b;
  top:47%;
  animation:out 0.2s cubic-bezier(1, 0, 0.58, 0.97) 1 both;
  outline: none;
}
.NavLink:hover:before{
  animation:in 0.2s cubic-bezier(1, 0, 0.58, 0.97) 1 both;
  outline: none;
}
.NavLink:focus {
  outline: none; /* Remove the blue highlight */
}
@keyframes in{
  0%{
    width: 0;
    left:0;
    right:auto;
  }
  100%{
    left:0;
    right:auto;
    width: 100%;
  }
}
@keyframes out{
  0%{
    width:100%;
    left: auto;
    right: 0;
  }
  100%{
    width: 0;
    left: auto;
    right: 0;
  }
}
@keyframes show{
  0%{
    opacity:0;
    transform:translateY(-10px);
  }
  100%{
    opacity:1;
    transform:translateY(0);
  }
}

.hamburger {
  display: none;
  @media (max-width: 768px) {
    display: block; /* Show hamburger menu on mobile */
    -webkit-tap-highlight-color: transparent;
    padding: 20px;
    top: 0;
    width: 7%;
    
  }
}
.hamburger .line{
  width: 80%;
  height: 5px;
  
  background-color: gray;
  display: block;
  margin: 2px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

}

.hamburger:hover{
  cursor: pointer;
}

/* ONE */

.hamburger.is-active .line:nth-child(2){
  opacity: 0;
}

.hamburger.is-active .line:nth-child(1){
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
  margin: 8px auto;
}

.hamburger.is-active .line:nth-child(3){
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
  margin: 8px auto;
}
