
.wholethang {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mmain {
  flex: 1;
  background: white;
}

.footer {
  background-color: whitesmoke;
  color: gainsboro;
  padding: 10px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links a {
  color: gray;
  text-decoration: none;
  margin: 5px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.slay {
  background-color: whitesmoke;
}