.commentyar,.null {
    width: 500px;
    height: auto;
    -webkit-tap-highlight-color: transparent;
    align-self: center;
    background-color: #fff;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 2px;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #41403e;
    cursor: pointer;
    display: inline-block;
    font-family: Neucha, sans-serif;
    font-size: 1rem;
    line-height: 23px;
    outline: none;
    padding: .75rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin: 15px;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 10px;
      width: 90%;
  }
}
.backbtn, .comentbtn , .inputt{
    height: auto;
    -webkit-tap-highlight-color: transparent;
    align-self: center;
    background-color: #fff;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 2px;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #41403e;
    cursor: pointer;
    display: inline-block;
    font-family: Neucha, sans-serif;
    font-size: 1rem;
    line-height: 23px;
    outline: none;
    padding: .75rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin: 30px;
    margin-top: 50px;
    margin-left: 50px;
    text-align: center;
    @media (max-width: 600px) {
      font-size: 12px;
      margin-top: 10px;
      margin-left: 10px;
      margin-bottom: 0px;
  }
}

.commentyar:hover,
.backbtn:hover,
.comentbtn:hover , .inputt:hover{
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
    transform: translate3d(0, 2px, 0);
  }
    
  .commentyar:focus,
  .backbtn:focus,.comentbtn:focus , .inputt:focus {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
  }

.commentyar h4, .themeat p {
    overflow-wrap: break-word; /* Ensures text breaks into new lines */
    white-space: normal; /* Allows text wrapping */
    overflow: hidden; /* Prevents horizontal scrolling */
    text-overflow: ellipsis; /* Adds "..." if text is truncated */
    
}

.userId{
    display: flex;
    justify-content: center;
    @media (max-width: 600px) {
        display: block;
        text-align: center;
    }
}

.userId h3, .userId h4{
    margin: 10px;
    
    
}
.userId h3 {
    font-size: 20px;
}
.userId h4 {
    font-size: 12px;
    font-weight: lighter;
}
.blogpage h1, .blogpage h3 {
    margin: 50px;
}
.themeat {
    padding: 50px;
    padding-top: 0px;
    display: flex;
    justify-content: left;
    padding-bottom: 0px;
    @media (max-width: 600px) {
        display: block;
        padding: 0px;
        justify-content: center;
        text-align: center;
    }
}

.textt {
   width: 60%;
   padding: 30px;
   line-height: 2;
   @media (max-width: 600px) {
    width: 80%;
    padding-top: 0px;
    }
}
.commentContainer {
    padding: 30px;
    margin: 20px;
}

.null {
    font-weight: lighter;
    @media (max-width: 600px) {
        font-size: 30px;
        }
}

.lol {
    width: 600px;
    padding: 30px;
    border: 5px solid whitesmoke;
}

.admin-user {
    color: rgb(255, 111, 190); /* Change the color to whatever you prefer */
    /* Add any other styles you want to apply to admin users */
}
.thetea {
    justify-content: center;
    display: block;
    text-align: center;
}

.pictureContainz {
    display: block;
    padding: 5px;
  }

.pictureContainz img {
    width: 300px;
    @media (max-width: 400px) {
        width: 250px;
    }
    
}