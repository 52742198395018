.projectContain {
    -webkit-tap-highlight-color: transparent;
    align-self: center;
    background-color: #fff;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 2px;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #41403e;
    cursor: pointer;
    display: inline-block;
    font-family: Neucha, sans-serif;
    font-size: 1rem;
    line-height: 23px;
    outline: none;
    padding: .75rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    background-color: #D3B1C2;;
    width: 80%;
    padding: 10px;
    margin: 10px;
    
}

.projectContain:hover {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
    transform: translate3d(0, 2px, 0);
}

.projectContain:focus {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}
.gridddy {
  width: 90%;
}
.gridd {
  
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Horizontally center */
  align-items: center;
  
  
}

.grid-layout {
    display: grid;
    text-align: center;
    grid-template-columns: repeat(3, 1fr); /* 4 equal-width columns */
     /* Space between grid items */
    row-gap: 5px;
    
}
@media (max-width: 800px) {
    .grid-layout {
      grid-template-columns: 1fr; /* Change to one column for mobile */
      gap: 10px; /* Optionally adjust the gap for mobile */
      padding: 20px;
    }
  }
.visit_project {
    color: cornflowerblue;
    text-decoration: underline;
}
.visit_project:hover {
    text-decoration: none;
}

.item {
    /*background-color: #f9f9f9;
    border: 1px solid #ddd; */
    padding: 10px;
    text-align: center;
}

.heading {
    display: block;
    text-align: center;
    font-family: Neucha, sans-serif;
    font-size: 1rem;
    
}
 .projectContain h3 {
    overflow-wrap:normal; /* Ensures text breaks into new lines */
    white-space: normal; /* Allows text wrapping */
    overflow: hidden; /* Prevents horizontal scrolling */
    text-overflow: ellipsis; /* Adds "..." if text is truncated */
     /* Optional padding */
     padding: 2px;
     @media (max-width: 800px) {
      padding: 2px;
      
      }
}
.projectContain h1{
  width: 100%;
  margin-left: 10px;
  
  overflow-wrap:normal; /* Ensures text breaks into new lines */
    white-space: normal; /* Allows text wrapping */
      text-overflow: ellipsis; /* Adds "..." if text is truncated */
  font-size: 20px;
  
  line-height: 1;
  @media (max-width: 800px) {
    
    line-height: 1;
    
    }
}

.projectContain h3 {
    font-size: 15px;
    font-weight: lighter;
}

/* CSS */
.nextnext {
    align-items: center;
    appearance: none;
    background-color: #fff;
    border-radius: 20px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    box-sizing: border-box;
    color: #3c4043;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 30px;
    width: 15px;
    justify-content: center;
    letter-spacing: .25px;
    line-height: normal;
    max-width: 80%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: transform,opacity;
    z-index: 0;
    margin: 20px;
  }
  
  .nextnext:hover {
    background: #F6F9FE;
    color: pink;
  }
  
  .nextnext:active {
    box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
    outline: none;
  }
  
  .nextnext:focus {
    outline: none;
  }
  
  .nextnext:not(:disabled) {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }
  
  .nextnext:not(:disabled):hover {
    box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
  }
  
  .nextnext:not(:disabled):focus {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }
  
  .nextnext:not(:disabled):active {
    box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
  }
  
  .nextnext:disabled {
    box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
  }


  .textimg {
    display: block;
    text-align: left;
  }
  p {
    margin-left: 10px;
  }
  .na {
    text-align: center;
    display: block;
    margin: 50px;
  }
  .loader {
    width: 15px;
    text-align: center;
    align-items: center;
    margin: auto;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: l5 1s infinite linear alternate;
  }
  @keyframes l5 {
      0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
      33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
      66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
      100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
  }
  