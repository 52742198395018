.tag,
.postContain {
  -webkit-tap-highlight-color: transparent;
  align-self: center;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: inline-block;
  font-family: Neucha, sans-serif;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: .75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
  
.tag:hover,
.postContain:hover {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
  transform: translate3d(0, 2px, 0);
}
  
.tag:focus,
.postContain:focus {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}
.Hytech {
  background-color: #D5C6E0;
}

.Baking {
  background-color: #AAA1C8;
}
.Exchange24 {
  background-color: #967AA1;
}

.Gatech {
  background-color: #FFD4DB;
}
.grid-layout-post {
  display: grid; /* Enable grid layout */
  grid-template-columns: repeat(4, 1fr); /* 2 equal-width columns */
  gap: 20px; /* Space between grid items */
  padding: 20px;
  margin: 10px;
}
@media (max-width: 830px) {
  .grid-layout-post {
    grid-template-columns: 1fr; /* Change to one column for mobile */
    gap: 10px; /* Optionally adjust the gap for mobile */
  }
}

.item {
  padding: 10px;
  text-align: center;
}
.postContain img {
  max-width: 100%; /* Ensure images don't overflow */
}

.firstContain {
  display: block;
  text-align: center;
}

.yar, .postContain h3, .postContain p {
  overflow-wrap: break-word; /* Ensures text breaks into new lines */
  white-space: normal; /* Allows text wrapping */
  overflow: hidden; /* Prevents horizontal scrolling */
  text-overflow: ellipsis; /* Adds "..." if text is truncated */
  padding: 10px; /* Optional padding */
  -webkit-tap-highlight-color: transparent;
}
.tag {
  margin: 10px;
}

/* Centering Container */
.center-con {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

/* Round Element Styles */
.round {
  position: absolute;
  border: 2px solid #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Use 50% for perfect circles */
}

/* CTA (Call to Action) Styling */
#cta {
  width: 100%;
  cursor: pointer;
  position: absolute;
}

#cta .arrow {
  left: 30%;
}

.arrow {
  position: absolute;
  bottom: 0;
  margin-left: 0;
  width: 12px;
  height: 12px;
  background-size: contain;
  top: 15px;
}

.segunda {
  margin-left: 8px;
}

/* Keyframes for Bounce Animation */
@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateX(10px) scale(0.9);
  }
  26% {
    opacity: 0;
    transform: translateX(-10px) scale(0.9);
  }
  55% {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}

.pictureContain {
  display: block;
  margin: 10px;
  padding: 5px;
}

/* Class for Applying the Bounce Animation */
.bounceAlpha {
  animation: bounceAlpha 1.4s linear infinite;
}

/* Hover Behavior for Round Element */
.round:hover .arrow {
  animation: bounceAlpha 1.4s linear infinite;
}

.round:hover .arrow.primera {
  animation: bounceAlpha 1.4s linear 1.4s; /* Delayed animation */
}


/* CSS */
.nextnext {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 20px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 30px;
  width: 15px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 80%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  will-change: transform,opacity;
  z-index: 0;
}

.nextnext:hover {
  background: #F6F9FE;
  color: pink;
}

.nextnext:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.nextnext:focus {
  outline: none;
}

.nextnext:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.nextnext:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.nextnext:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.nextnext:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.nextnext:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.yar {
  color: #362417;
  text-decoration: solid;
  font-weight: bold;
  font-size: 25px;
  
}
.yar:hover {
  color: #F0F7E0;
  stroke-dashoffset: 0;
	transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1);
	transition-duration: 0.3s;
}

.postContain img {
  width: 200px;
  border: 5px solid whitesmoke;
}
.postContain h3 {
  font-size: 15px;
  font-weight: lighter;
}
.na {
  text-align: center;
  display: block;
  margin: 50px;
}