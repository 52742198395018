.submitBtn,
.addPostBtn,
.addProjectBtn,
.onCloseBtn,
.registerBtn,
.noAccountYet,
.tag1,
.tag2,
.tag3, 
.Addpost,
.Addproject,
.loginContain,
.important,
.shii {
    -webkit-tap-highlight-color: transparent;
    align-self: center;
    background-color: #fff;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 2px;
    box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #41403e;
    cursor: pointer;
    display: inline-block;
    font-family: Neucha, sans-serif;
    font-size: 1rem;
    line-height: 23px;
    outline: none;
    padding: .75rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}
.submitBtn:hover,
.addPostBtn:hover,
.addProjectBtn:hover,
.onCloseBtn:hover,
.registerBtn:hover,
.noAccountYet:hover,
.tag1:hover,
.tag2:hover,
.tag3:hover,
.Addpost:hover,
.Addproject:hover,
.loginContain:hover,
.important:hover,
.shii:hover {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
    transform: translate3d(0, 2px, 0);
}
.submitBtn:focus,
.addPostBtn:focus,
.addProjectBtn:focus,
.onCloseBtn:focus,
.registerBtn:focus,
.noAccountYet:focus,
.tag1:focus,
.tag2:focus,
.tag3:focus,
.Addpost:focus,
.Addproject:focus,
.loginContain:focus,
.important:focus,
.shii:focus {
    box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
}

.postPreview {
    width: 50%;
    height: auto;
}

.Addpost,
.Addproject {
    display: block;
    justify-content: center;
}

.popupContainer {
    display: block;
    text-align: center;
}
.Login {
    display: block;
    text-align: center;
}
.buttonContain {
    padding: 20px;
}
.submitBtn, .noAccountYet {
    margin: 5px;
}

.usernameLogin, .passwordLogin, .username, .password, .repassword {
    width: 25%;
    height: 15px;
    border-radius: 4px;
    padding-left: 12px;
    font-size: 14px;
    font-weight: normal;
    border: 1px solid rgb(137, 151, 155);
    transition: border-color 150ms ease-in-out 0s;
    outline: none;
    color: rgb(33, 49, 60);
    background-color: rgb(255, 255, 255);
    padding-right: 12px;
    :hover{
        box-shadow: rgb(231 238 236) 0px 0px 0px 3px;
    }
    margin: 5px;
}
.modal {
    padding: 10px;
    text-align: center;
    
}

.registerBtn {
    margin: 10px;
}

.loginContain {
    margin: 20px;
}



@keyframes tail {
  6.6666666667% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(10deg);
  }
  16.6666666667% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(30deg);
  }
  26.6666666667% {
    transform: rotate(-2deg);
  }
  46.6666666667% {
    transform: rotate(10deg);
  }
  53.3333333333% {
    transform: rotate(-5deg);
  }
  56.6666666667% {
    transform: rotate(10deg);
  }
}
@keyframes body {
  6.6666666667% {
    transform: scaleY(1);
  }
  10% {
    transform: scaleY(1.15);
  }
  16.6666666667% {
    transform: scaleY(1);
  }
  20% {
    transform: scaleY(1.25);
  }
  26.6666666667% {
    transform: scaleY(1);
  }
  46.6666666667% {
    transform: scaleY(1.15);
  }
  53.3333333333% {
    transform: scaleY(1);
  }
  56.6666666667% {
    transform: scaleY(1.15);
  }
}
@keyframes left-whisker {
  6.6666666667% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(0deg);
  }
  16.6666666667% {
    transform: rotate(-5deg);
  }
  20% {
    transform: rotate(0deg);
  }
  26.6666666667% {
    transform: rotate(0deg);
  }
  46.6666666667% {
    transform: rotate(10deg);
  }
  53.3333333333% {
    transform: rotate(-5deg);
  }
  56.6666666667% {
    transform: rotate(10deg);
  }
}
@keyframes right-whisker {
  6.6666666667% {
    transform: rotate(180deg);
  }
  10% {
    transform: rotate(190deg);
  }
  16.6666666667% {
    transform: rotate(180deg);
  }
  20% {
    transform: rotate(175deg);
  }
  26.6666666667% {
    transform: rotate(190deg);
  }
  46.6666666667% {
    transform: rotate(180deg);
  }
  53.3333333333% {
    transform: rotate(185deg);
  }
  56.6666666667% {
    transform: rotate(175deg);
  }
}
@keyframes left-ear {
  0% {
    transform: rotate(-20deg);
  }
  6.6666666667% {
    transform: rotate(-6deg);
  }
  13.3333333333% {
    transform: rotate(-15deg);
  }
  26.6666666667% {
    transform: rotate(-15deg);
  }
  33.3333333333% {
    transform: rotate(-30deg);
  }
  40% {
    transform: rotate(-30deg);
  }
  46.6666666667% {
    transform: rotate(0deg);
  }
  53.3333333333% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(-15deg);
  }
  80% {
    transform: rotate(-15deg);
  }
  93.3333333333% {
    transform: rotate(-6deg);
  }
  100% {
    transform: rotateZ(-6deg);
  }
}
@keyframes right-ear {
  0% {
    transform: rotateZ(-16deg);
  }
  6.6666666667% {
    transform: rotateZ(-16deg);
  }
  13.3333333333% {
    transform: rotateZ(-19deg);
  }
  26.6666666667% {
    transform: rotateZ(-19deg);
  }
  33.3333333333% {
    transform: rotateZ(-30deg);
  }
  36.6666666667% {
    transform: rotateZ(-19deg);
  }
  37.3333333333% {
    transform: rotateZ(-30deg);
  }
  38% {
    transform: rotateZ(-19deg);
  }
  40% {
    transform: rotateZ(-19deg);
  }
  40.6666666667% {
    transform: rotateZ(-30deg);
  }
  41.3333333333% {
    transform: rotateZ(-19deg);
  }
  46.6666666667% {
    transform: rotateZ(-9deg);
  }
  53.3333333333% {
    transform: rotateZ(-9deg);
  }
  60% {
    transform: rotateZ(-19deg);
  }
  60.6666666667% {
    transform: rotateZ(-30deg);
  }
  61.3333333333% {
    transform: rotateZ(-19deg);
  }
  62.6666666667% {
    transform: rotateZ(-19deg);
  }
  63.3333333333% {
    transform: rotateZ(-30deg);
  }
  64% {
    transform: rotateZ(-19deg);
  }
  80% {
    transform: rotateZ(-19deg);
  }
  93.3333333333% {
    transform: rotateZ(-16deg);
  }
  100% {
    transform: rotateZ(-16deg);
  }
}

.catt {
  position: absolute; /* or fixed, depending on your layout */
  bottom: 0;
  left: 0;
  @media (max-width: 350px) {
    position: fixed;
    bottom: 0px;
    
  }
}
.main {
  height: 400px;
  width: 370px;
  position: relative;
  @media (max-width: 600px) {
    width: 370px;
    position: fixed;
    bottom: 0;
    right: -40%;
  }
}
.main .stand {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  height: 20px;
  width: 200px;
  border-radius: 20px;
  background-color: #d6b2ef;
  z-index: 2;
}
.main .stand::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%);
  height: 10px;
  width: 50px;
  border-radius: 20px;
  background-color: #8997de;
  box-shadow: 0 10px 0 #bcc5f3, 0 20px 0 #bcc5f3, 0 30px 0 #bcc5f3, 0 40px 0 #bcc5f3, 0 50px 0 #bcc5f3, 0 60px 0 #bcc5f3, 0 70px 0 #bcc5f3, 0 80px 0 #bcc5f3, 0 90px 0 #bcc5f3, 0 100px 0 #bcc5f3, 0 110px 0 #bcc5f3, 0 120px 0 #bcc5f3, 0 130px 0 #bcc5f3, 0 140px 0 #bcc5f3, 0 150px 0 #bcc5f3, 0 160px 0 #bcc5f3, 0 170px 0 #bcc5f3;
  @media (max-width: 600px) {
    
  }

}
.main .cat {
  width: 110px;
  height: 50px;
  position: absolute;
  top: calc(50% - 50px);
  right: 130px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
}
.main .cat .body {
  width: 110px;
  height: 50px;
  background-color: #745260;
  position: absolute;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  animation: body 12s none infinite;
}
.main .cat .head {
  content: "";
  width: 70px;
  height: 35px;
  background-color: #745260;
  position: absolute;
  top: calc(50% - 10px);
  left: -40px;
  border-top-left-radius: 80px;
  border-top-right-radius: 80px;
}

.tail-container {
  position: absolute;
  right: 0;
  bottom: -13px;
  z-index: 3;
}

.tail {
  position: absolute;
  height: 30px;
  width: 14px;
  bottom: -10px;
  right: 0;
  border-bottom-right-radius: 5px;
  background: #745260;
  z-index: 0;
}
.tail > .tail {
  animation: tail 12s none infinite;
  height: 100%;
  width: 14px;
  transform-origin: left;
  border-bottom-left-radius: 20px 20px;
  border-bottom-right-radius: 20px 20px;
  border-top-right-radius: 40px;
}

.ear {
  position: absolute;
  left: 4px;
  top: -4px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 20px solid #745260;
  transform: rotate(-30deg);
  animation: left-ear 12s both infinite;
}
.ear + .ear {
  animation: right-ear 12s both infinite;
  top: -12px;
  left: 30px;
}

.nose {
  position: absolute;
  bottom: 10px;
  left: -10px;
  background-color: #fd6e72;
  height: 5px;
  width: 5px;
  border-radius: 50%;
}

.whisker-container {
  position: absolute;
  bottom: 5px;
  left: -36px;
  width: 20px;
  height: 10px;
  transform-origin: right;
  animation: left-whisker 12s both infinite;
}
.whisker-container:nth-child(2) {
  left: -20px;
  bottom: 12px;
  transform-origin: right;
  transform: rotate(180deg);
  animation: right-whisker 12s both infinite;
}

.whisker {
  position: absolute;
  top: 0;
  width: 100%;
  border: 1px solid #fdf9de;
  transform-origin: 100% 0;
  transform: rotate(10deg);
}
.whisker:last-child {
  top: 0;
  transform: rotate(-20deg);
}

.shii {
  margin: 15px;
  border-radius: 10%;
  background-color: indianred;
}
.post_imgpath, .project_imgpath {
  display: block;
}




.adminContent {
  max-height: 70vh; /* Set a maximum height for the popup content */
  overflow-y: auto; /* Enable vertical scrolling if the content overflows */
  
}